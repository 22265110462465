/* I think IBM Plex Sans would be a great combination */
/* @import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@200;300;400;500;600&display=swap"); */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 300 !important;
}
h3 {
  font-weight: 400 !important;
}

@media (max-width: 380px) {
  .flex-icons {
    display: block !important;
  }
  .world-column {
    display: none;
  }
  .home-video-mobile {
    flex-flow: column-reverse;
  }
  .home-video-btn {
    width: 100% !important;
  }
}

@media screen and (max-width: 600px) {
  .world-column {
    display: none;
  }
  .side-menu {
    display: none;
  }

  .location_section {
    height: 780px !important;
  }

  .location_section_nepal {
    height: 1220px !important;
  }

  .location_section_haiti {
    height: 980px !important;
  }

  .location_section_madagascar {
    height: 930px !important;
  }
}

#worlds {
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: #cefaeb; */
  overflow: hidden;
  display: contents;
}

canvas {
  background: #cefaeb;
}

/* make button group on homepage more visible */
.btn-light {
  color: #212529;
  background-color: #e4e8eb;
  border-color: #e4e8eb;
}

/* Can't download for screens below 1000 pixels ... */
@media screen and (max-width: 1000px) {
  .download_cert {
    display: none;
  }
  .print_cert_div {
    display: none;
  }
}
.btn-light {
  background-color: #f5f5dd;
}

@-webkit-keyframes glow {
  0% {
    box-shadow: 0 0 0 0 #b1cc33;
  }
  50% {
    box-shadow: 0 0 30px 0 #b1cc33;
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 0 0 #b1cc33;
  }
  50% {
    box-shadow: 0 0 30px 0 #b1cc33;
  }
}

.card-glow {
  background: #b1cc33;
  box-shadow: 0 0 0 0 #b1cc33;
  -webkit-animation: glow 3s linear infinite;
  animation: glow 3s linear infinite;
}

.example_gift_home_btn {
  background-color: #0173e6b8 !important;
  border-color: #5597e5 !important;
}

@import url("https://fonts.googleapis.com/css2?family=Spirax");
@import url("https://fonts.googleapis.com/css2?family=Lobster+Two:wght@400;700");
