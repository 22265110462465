.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-group.required .control-label:after {
  content: "*";
  color: red;
}

/* QUOTES Front page */
.blockquote-custom {
  position: relative;
  font-size: 1.1rem;
}

.blockquote-custom-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -25px;
  left: 50px;
}

/* Error css for gift */
.error-template {
  padding: 40px 15px;
  text-align: center;
}
.error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
}
.error-actions .btn {
  margin-right: 10px;
}

/* CSS for gift comments */
.box {
  width: 100%;
  margin: 35px auto;
  border-radius: 0.8em;
  background: #d4edda;
  text-align: center;
  font-weight: 900;
  font-family: arial;
  position: relative;
  color: #155724;
}

.sb3:before {
  /* content: ""; */
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid #d4edda;
  border-top: 10px solid #d4edda;
  border-bottom: 10px solid transparent;
  left: 19px;
  bottom: -19px;
}

.sb4:before {
  /* content: ""; */
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid #d4edda;
  border-top: 10px solid #d4edda;
  border-bottom: 10px solid transparent;
  right: 19px;
  bottom: -19px;
}

/* experimental cards */
.text-center {
  text-align: center;
}
.cf {
  *zoom: 1;
}
.cf:before,
.cf:after {
  content: " ";
  display: table;
}
.cf:after {
  clear: both;
}

body .wrapper {
  max-width: 450px;
  margin: 0 auto;
}
body .wrapper h1 {
  margin-top: 50px;
  margin-bottom: 50px;
  text-transform: uppercase;
  font-size: 1.5em;
}
body .wrapper .speechbubble {
  background-color: #d4edda;
  color: #155724;
  line-height: 1.75;
  padding: 25px 25px;
  margin-bottom: 35px;
  cursor: default;
  white-space: pre-line;
}
body .wrapper .speechbubble:nth-child(2n) {
  border-left: 5px solid;
}
body .wrapper .speechbubble:nth-child(2n):after {
  /* content: ""; */
  margin-top: -30px;
  padding-top: 0px;
  position: relative;
  bottom: -45px;
  left: 20px;
  border-width: 30px 0 0 30px;
  border-style: solid;
  border-color: #d4edda transparent;
  display: block;
  width: 0;
}
body .wrapper .speechbubble:nth-child(2n + 1) {
  border-right: 5px solid;
}
body .wrapper .speechbubble:nth-child(2n + 1):after {
  /* content: ""; */
  margin-top: -30px;
  padding-top: 0px;
  position: relative;
  bottom: -45px;
  left: 350px;
  border-width: 30px 30px 0 0;
  border-style: solid;
  border-color: #d4edda transparent;
  display: block;
  width: 0;
}
body .wrapper .speechbubble:nth-child(4n + 1) {
  border-color: #56b273;
}
body .wrapper .speechbubble:nth-child(4n + 2) {
  border-color: #01ad9b;
}
body .wrapper .speechbubble:nth-child(4n + 3) {
  border-color: #b388dd;
}
body .wrapper .speechbubble:nth-child(4n + 4) {
  border-color: #ff8750;
}
body .wrapper .speechbubble p:before {
  content: "“";
  font-family: Georgia;
  font-size: 40px;
  line-height: 0;
  display: inline-block;
  display: -webkit-inline-box;
}
body .wrapper .speechbubble .username {
  display: inline;
  font-style: italic;
  float: right;
}
body .wrapper .speechbubble .username:before {
  content: "- ";
}

body .wrapper .speechbubble .username_v2 {
  display: inline;
  float: right;
  font-weight: bold;
  color: rgba(21, 87, 36, 0.5);
}

/* Dropdown colours */
#basic-nav-dropdown {
  color: black;
}

#basic-nav-dropdown-countries {
  color: #00000080;
}

.left-dropdown {
  right: 0;
  left: auto !important;
}

.dropdown-item:active {
  background-color: #b1cc33 !important;
}

/* Experimenting with loader */
.loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 1;
}

.loader {
  left: 50%;
  margin-left: -4em;
  font-size: 10px;
  border: 0.8em solid rgba(218, 219, 223, 1);
  border-left: 0.8em solid rgba(58, 166, 165, 1);
  animation: spin 1.1s infinite linear;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -4.05em;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* over 500 trees planted section  */
.rich-text__heading::before {
  content: "";
  display: block;
  width: 0;
  height: 6px;
  margin-bottom: 20px;
  background-color: #b1cc33;
  transition: width 0.5s ease-out;
  margin: 0 auto 20px;
}
.rich-text__heading.has-animated::before,
.supports-no-js .rich-text__heading::before,
.animations--disabled .rich-text__heading::before {
  width: 80px;
}

/*  New homepage */
.hero-background-color-block {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  width: 40vw;
  height: 100%;
  background-color: #b1cc33;
}

.grid-halves.overlap-grid-halves {
  position: relative;
  z-index: 1;
}

.grid-halves {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

/* .container {
    position: relative;
    width: 100%;
    max-width: 1296px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 24px;
    padding-left: 24px;
} */

.align-center {
  margin-right: auto;
  margin-left: auto;
}

.content-width-large {
  width: 100%;
  max-width: 506px;
}

@media screen and (max-width: 767px) {
  .section {
    padding-top: 0px !important;
    padding-bottom: 72px;
  }
}
@media screen and (max-width: 991px) {
  .grid-halves {
    justify-items: center;
    grid-column-gap: 48px;
    grid-row-gap: 48px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 991px) {
  .grid-halves {
    justify-items: center;
    grid-column-gap: 48px;
    grid-row-gap: 48px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 479px) {
  .hero-background-color-block {
    height: 72vw;
  }
}
@media screen and (max-width: 767px) {
  .hero-background-color-block {
    position: absolute;
    width: 100%;
    height: 65vw;
  }
}
@media screen and (max-width: 991px) {
  .hero-background-color-block {
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: 30vh;
  }
}
ƒ
/* New how it works section */
.how-container {
  width: 25%;
  float: left;
}

.how-container div {
  margin: 10%;
}

.how-container img {
  width: 100%;
  height: 150px;
  display: block;
  margin: auto;
}

/* testing Highlighter css */

.green-highlight,
.pink-highlight,
.yellow-highlight {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding-left: 3px;
}

.green-highlight {
  background: #99ffcc; /* Default color, all browsers */
}

.green-highlight::selection {
  background: #99cccc; /* Selection color, WebKit/Blink Browsers */
}

.green-highlight::-moz-selection {
  background: #99cccc; /* Selection color, Gecko Browsers */
}

.pink-highlight {
  background: #ffccff; /* Default color, all browsers */
}

.pink-highlight::selection {
  background: #ff99ff; /* Selection color, WebKit/Blink Browsers */
}

.pink-highlight::-moz-selection {
  background: #ff99ff; /* Selection color, Gecko Browsers */
}

.yellow-highlight {
  background: #ffffcc; /* Default color, all browsers */
}

.yellow-highlight::selection {
  background: #ffff66; /* Selection color, WebKit/Blink Browsers */
}

.yellow-highlight::-moz-selection {
  background: #ffff66; /* Selection color, Gecko Browsers */
}

.chartjs-render-monitor {
  background: white;
}

/* Sticky spyscroll for gifts */
.list-group {
  position: sticky; /* Supported in latest version of Chrome, Firefox, Safari, Opera and Edge browsers */
  top: 15px;
}

.list-group-item.active {
  /* z-index: 2;
  color: #fff;
  font-weight: 800;
  background-color: #b1cc33;
  border-color: #b1cc33; */
  z-index: 2;
  color: #8ca133;
  font-weight: 800;
  background-color: #fefefe;
  border-color: #b1cc33;
  border-left-color: #b1cc40;
  border-left-width: 5px;
}

/* Staging navbar vertical divider */
.staging-nav-links {
  background: beige;
}

.btn-download {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

/* .btn-info.focus, .btn-info:focus, .btn-info:hover { */
.btn-download:focus,
.btn-download:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-download:not(:disabled):not(.disabled).active,
.btn-download:not(:disabled):not(.disabled):active,
.show > .btn-download.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info,
.btn-info:hover,
.btn-info:active,
.btn-info:visited,
.btn-info:focus {
  background-color: #b1cc33 !important;
  border-color: #b1cc33 !important;
}

.wrapper_message {
  max-width: 560px !important;
}

@media screen and (max-width: 479px) {
  /* GIFT PAGE */
  .contribute_button {
    width: 80% !important;
  }

  .planting_team_card {
    justify-content: center !important;
  }

  .gift_body {
    max-width: 100% !important;
    flex: auto;
  }

  .gallery_photo {
    padding-bottom: 10px;
  }

  .team_planter_card {
    margin-bottom: 15px;
  }
  .gift_titles {
    display: inline-block;
  }
}
